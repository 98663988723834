import React from "react"
import EuImage from "../assets/eu.png";
import {MdOutlineKeyboardArrowRight} from "react-icons/md";
import { Link } from "react-scroll";

const Home = () => {

  const dataInicial = new Date('2023-01-03');
  const dataAtual = new Date();
  const diferencaEmMilissegundos = dataAtual - dataInicial;
  const milissegundosPorMes = 30.44 * 24 * 60 * 60 * 1000;
  const monthsOfExpirence = Math.floor(diferencaEmMilissegundos / milissegundosPorMes);
  
  return (
    <div name="home" className="h-screen w-full bg-gradient-to-b from-black via-black to-gray-800"
    >
     <div className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row"
     >
        <div className="flex flex-col justify-center h-full"
        >
            <h2 className="text-4xl sm:text-7xl font-bold text-white ">I'm a Full Stack Developer</h2>
            <p className="text-gray-500 py-4 max-w-md ">
            I have {monthsOfExpirence} months of experience in building and designing software. Currently I'm working on technologies like C# and SQL.            
            </p>

            <div>
                <Link to="portfolio" smooth duration={500} className=" group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer">
                    Portfolio
                    <span className="group-hover:rotate-90 duration-300">
                    <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
                    </span>                  
                </Link>
            </div>
        </div>

        <div>
          <img src={EuImage} alt="my profile" className="rounded-2xl mx-auto w-2/3 md:w-full cursor-pointer" />
        </div>
     </div>
    </div>
  );
};

export default Home